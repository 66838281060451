<template>
    <v-data-table hide-default-footer :headers="headers" :items="value">
        <template v-slot:item.nbBook="{item}">
            <span v-if="item.nbBook != null">{{item.nbBook}}</span>
            <span v-else>0</span>
        </template>
        <template v-slot:item.action="{item}">
            <v-btn icon :to="{name:'StudentDetails', params: {id: item.id}}">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template>

        <template v-slot:no-data>
            <slot name="no-data"></slot>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    components: {},
    data() {
         return {}
    },
    computed: {
        headers() {
            const headers = []
            headers.push({text: this.$t('studentList.headers.name'),value:'name'});
            headers.push({text: this.$t('studentList.headers.studentCode'), value: 'studentCode'});
            headers.push({text: this.$t('studentList.headers.nbOfBook'), value: 'nbBooks', width: '5%'});
            headers.push({text: '', value: 'action', width: '5%'});

            return headers;
        }
    },
    watch: {},
    methods: {
    }
}
</script>