<template>
    <v-card elevation="0">
        <v-card-title>
            <v-toolbar elevation="0" dense>
                <v-btn icon :to="{name: 'Home'}" exact>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon v-on="on" v-bind="attrs" :to="{name: 'NewStudent'}">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    {{$t('studentList.addStudentButton.tooltip')}}
                </v-tooltip>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <student-grid v-model="students">
                <template v-slot:no-data>
                    <br />
                    <h5>{{$t('studentList.noStudents')}}</h5>
                    <v-btn block class="my-4" :to="{name: 'NewStudent'}">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </student-grid>
        </v-card-text>
    </v-card>
</template>
<script>
import StudentGrid from '../components/StudentList/StudentListGrid.vue'
export default {
    components: {StudentGrid},
    data() {
         return {
             students: []
         }
    },
    computed: {
        
    },
    watch: {},
    methods: {
        loadStudents() {
            
            this.$axios.get('/students').then(res => {
                this.students = res.data.data;
                if(this.students == null)
                    this.students = []
            })
        }
    },
    created() {
        this.loadStudents()
    }
}
</script>